html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background-color: #0e0e0e;
  font-family: sans-serif;
}

.Root {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.Cell {
  flex: auto;
  justify-content: center;
  align-items: center;
  padding: 2em;
  display: flex;
  position: relative;
}

.Cell:first-child {
  padding-right: 1em;
}

.Cell:last-child {
  padding-left: 1em;
}

.Cell:after {
  content: "";
  z-index: -1;
  background-color: #333;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 90vh;
  display: block;
}
/*# sourceMappingURL=index.1b9e6e83.css.map */
